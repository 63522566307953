export const copyToClipboard = async (textToCopy: string) => {
    if (navigator.clipboard && window.isSecureContext) {
        return await navigator.clipboard.writeText(textToCopy)
    } else {
        let textArea = document.createElement('textarea')
        textArea.value = textToCopy
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        return new Promise((res: any, rej): void => {
            document.execCommand('copy') ? res() : rej()
            textArea.remove()
        })
    }
}
