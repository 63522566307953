import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart,
    Filler,
    LineElement,
    LinearScale,
    PointElement,
    Tooltip
} from 'chart.js'
import DataGrowth from 'components/DataGrowth'
import Dynamics from 'components/Dynamics'
import Filledness from 'components/Filledness'
import Header from 'components/Header'
import Mempool from 'components/Mempool'
import NodeChainResult from 'components/NodeChainResult'
import Result from 'components/Result'
import TPS from 'components/TPS'
import Total from 'components/Total'
import { useConfig } from 'hooks/useConfig'
import { useEffect, useState } from 'react'
import { LastTestResponse } from 'models/Response'
import { EventType, WebSocketEvent } from 'models/WebSocketEvent'
import styles from './styles.module.scss'

Chart.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
    ArcElement,
    BarElement,
    Tooltip
)

const App: React.FC = () => {
    const { apiUrl, wssUrl } = useConfig()

    const [isRequestDone, setIsRequestDone] = useState(false)
    const [isTesting, setIsTesting] = useState(false)
    const [socket, setSocket] = useState<WebSocket | null>(null)

    // const [mempoolInfo, setMempoolInfo] = useState({
    //     count: 100,
    //     currentCount: 0
    // })
    const [transactionPerSec, setTransactionPerSec] = useState(0)
    const [totalTransactions, setTotalTransactions] = useState(0)
    const [currentTransactions, setCurrentTransactions] = useState(
        Array(8).fill(0)
    )
    const [percentTransactions, setPercentTransactions] = useState(
        Array(8).fill(0)
    )
    const [finishedTransactions, setFinishedTransactions] = useState(
        Array(8).fill(0)
    )
    const [dynamicTransactions, setDynamicTransactions] = useState(
        Array(8).fill(0)
    )
    const [result, setResult] = useState({
        transactionPerSec: 0,
        totalTransactions: 0
    })

    useEffect(() => {
        setTotalTransactions(finishedTransactions.reduce((a, b) => a + b))
    }, [finishedTransactions])

    useEffect(() => {
        if (!socket && isRequestDone) {
            const wss = new WebSocket(wssUrl)
            setSocket(wss)
        }

        if (socket) {
            socket.onopen = () => {
                console.log('wss connected')
            }

            socket.onmessage = event => {
                let message: WebSocketEvent = JSON.parse(event.data)
                switch (message.type) {
                    case EventType.ConnectionEvent:
                        break
                    case EventType.FillingMemPool:
                        setIsTesting(true)
                        // setMempoolInfo({
                        //     count: message.data.count,
                        //     currentCount: message.data.currentCount
                        // })
                        break
                    case EventType.GetStateEvent:
                        setIsTesting(true)
                        setTransactionPerSec(message.data.transactionPerSec)
                        setCurrentTransactions(
                            message.data.currentTransactions.info
                        )
                        setPercentTransactions(
                            message.data.hashedTransactions.info
                        )
                        setFinishedTransactions(
                            message.data.finishedTransactions.info
                        )
                        setDynamicTransactions(
                            message.data.dynamicTransactions.info
                        )
                        break

                    case EventType.EndStateEvent:
                        setIsTesting(false)
                        setResult({
                            transactionPerSec: message.data.transactionPerSec,
                            totalTransactions: message.data.totalTransactions
                        })
                        // setMempoolInfo({
                        //     count: 100,
                        //     currentCount: 0
                        // })
                        break
                    default:
                        console.log('Unknown event type:', event.type)
                }
            }

            socket.onclose = event => {
                console.log(
                    'WebSocket connection closed:',
                    event.code,
                    event.reason
                )
            }

            socket.onerror = event => {
                console.error('wss error')
            }

            return () => {
                console.log('Closing WebSocket connection')
                socket.close()
                setSocket(null)
            }
        }
    }, [socket, isRequestDone])

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal

        const fetchLast = async () => {
            try {
                const url = apiUrl + 'test/last'
                const response = await fetch(url, { signal })

                const data: LastTestResponse = await response.json()
                if (data.data) {
                    setTransactionPerSec(data.data.transactionPerSec)
                    setTotalTransactions(data.data.totalTransactionProcessed)
                    setCurrentTransactions(data.data.currentTransactions.info)
                    setPercentTransactions(data.data.hashedTransactions.info)
                    setFinishedTransactions(data.data.finishedTransactions.info)
                    setDynamicTransactions(data.data.dynamicTransactions.info)
                    setResult({
                        transactionPerSec: data.data.transactionPerSec,
                        totalTransactions: data.data.totalTransactionProcessed
                    })
                }
            } catch (error) {
                console.error('Error:', error)
            } finally {
                setIsRequestDone(true)
            }
        }

        fetchLast()

        return () => controller.abort()
    }, [])

    const handleClickTest = async () => {
        try {
            const url = apiUrl + 'test/start'
            const body = {}

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if (!response.ok) {
                throw new Error('Failed to start test')
            }

            console.log('Test started successfully')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={styles.wrapper}>
            <Header onClickTest={handleClickTest} isTesting={isTesting} />

            <p className={styles.title}>SoftNote Performance Test</p>

            <div className={styles.content}>
                <TPS transactions={transactionPerSec} />
                <Total total={totalTransactions} />
                <Dynamics dynamicTransactions={dynamicTransactions} />
                {/* <Mempool mempoolInfo={mempoolInfo} /> */}
                <DataGrowth currentTransactions={currentTransactions} />
                <Filledness percentTransactions={percentTransactions} />
                <NodeChainResult finishedTransactions={finishedTransactions} />
                <Result result={result} isTesting={isTesting} />
            </div>
        </div>
    )
}

export default App
