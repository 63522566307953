export type ConnectionEvent = {
    type: 'ConnectionEvent'
    id: string
    data: {
        id: string
    }
}

export type FillingMemPool = {
    type: 'FillingMemPool'
    id: string
    data: {
        count: number
        currentCount: number
    }
}

export type GetStateEvent = {
    type: 'GetStateEvent'
    id: string
    data: {
        number: number
        transactionPerSec: number
        currentTransactions: {
            info: number[]
        }
        hashedTransactions: {
            info: number[]
        }
        finishedTransactions: {
            info: number[]
        }
        dynamicTransactions: {
            info: number[]
        }
    }
}

export type EndStateEvent = {
    type: 'EndStateEvent'
    id: string
    data: {
        transactionPerSec: number
        totalTransactions: number
    }
}

export enum EventType {
    ConnectionEvent = 'ConnectionEvent',
    FillingMemPool = 'FillingMemPool',
    GetStateEvent = 'GetStateEvent',
    EndStateEvent = 'EndStateEvent'
}

export type WebSocketEvent =
    | ConnectionEvent
    | FillingMemPool
    | GetStateEvent
    | EndStateEvent
