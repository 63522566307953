import { Bar } from 'react-chartjs-2'
import styles from './styles.module.scss'

interface Props {
    currentTransactions: number[]
}

const DataGrowth: React.FC<Props> = props => {
    const { currentTransactions } = props

    const data = {
        labels: [
            'Node 1',
            'Node 2',
            'Node 3',
            'Node 4',
            'Node 5',
            'Node 6',
            'Node 7',
            'Node 8'
        ],
        datasets: [
            {
                label: 'Transactions',
                data: currentTransactions,
                borderRadius: 6,
                minBarLength: 4,
                backgroundColor: (context: any) => {
                    if (!context.chart.chartArea) {
                        return
                    }
                    const {
                        ctx,
                        data,
                        chartArea: { top, bottom }
                    } = context.chart
                    const gradientBg = ctx.createLinearGradient(
                        0,
                        top,
                        0,
                        bottom
                    )
                    gradientBg.addColorStop(0, '#1958FE')
                    gradientBg.addColorStop(1, '#9FBAFF')
                    return gradientBg
                }
            }
        ]
    }

    const options = {
        scales: {
            y: {
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                ticks: {
                    display: false
                }
            },
            x: {
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                ticks: {
                    color: '#8C93B2'
                }
            }
        }
    }

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Node Data Growth</span>

            <div className={styles.chartWrapper}>
                <Bar data={data} options={options} />
            </div>
        </div>
    )
}

export default DataGrowth
