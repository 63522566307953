import { Line } from 'react-chartjs-2'
import { lineChartShadowPlugin } from 'utils/charts/plugins'
import styles from './styles.module.scss'

interface Props {
    dynamicTransactions: number[]
}

const Dynamics: React.FC<Props> = props => {
    const { dynamicTransactions } = props

    const data = {
        labels: ['BTC', 'ETH', 'TRX', 'SOL', 'XRP', 'BNB', 'APT', 'SUI'],
        datasets: [
            {
                data: dynamicTransactions,
                backgroundColor: (context: any) => {
                    if (!context.chart.chartArea) {
                        return
                    }
                    const {
                        ctx,
                        data,
                        chartArea: { top, bottom }
                    } = context.chart
                    const gradientBg = ctx.createLinearGradient(
                        0,
                        top,
                        0,
                        bottom
                    )
                    gradientBg.addColorStop(0, '#2261F652')
                    gradientBg.addColorStop(1, '#2261F600')
                    return gradientBg
                },
                borderColor: '#2261F6',
                borderWidth: 4,
                fill: true,
                tension: 0.4,
                color: '#666'
            }
        ]
    }

    const options = {
        scales: {
            y: {
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                ticks: {
                    color: '#8C93B2'
                },
                min: 0
            },
            x: {
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                ticks: {
                    color: '#8C93B2'
                }
            }
        },
        elements: {
            point: {
                radius: 2
            }
        },

        locale: 'en-US'
    }

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Transaction Dynamics</span>

            <div className={styles.chartWrapper}>
                <Line
                    data={data}
                    options={options}
                    plugins={[lineChartShadowPlugin]}
                />
            </div>

            <span className={styles.description}>Transaction growth graph</span>
        </div>
    )
}

export default Dynamics
