import LogoImg from '../../assets/Tectum Logo M.svg'
import styles from './styles.module.scss'

interface Props {
    onClickTest: () => void
    isTesting: boolean
}

const Header: React.FC<Props> = props => {
    const { onClickTest, isTesting } = props
    return (
        <div className={styles.wrapper}>
            <img className={styles.logo} src={LogoImg} alt='logo' />
            {isTesting && (
                <div className={styles.testing}>
                    <div className={styles.loader} />
                    <span>Testing</span>
                </div>
            )}

            <button
                className={`${styles.button} ${isTesting ? styles.disabled : ''}`}
                onClick={onClickTest}
            >
                Test Speed
            </button>
        </div>
    )
}

export default Header
