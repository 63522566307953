import formattedNumber from 'utils/formatNumber'
import ProgressBar from './ProgressBar'
import styles from './styles.module.scss'

interface Props {
    transactions: number
}

const TPS: React.FC<Props> = props => {
    const { transactions } = props

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Transactions Per Second</span>

            <ProgressBar transactions={transactions} />

            <span className={styles.amount}>
                {formattedNumber(transactions)}
            </span>

            <span className={styles.description}>
                Transaction rate per second at the moment
            </span>
        </div>
    )
}

export default TPS
