export const lineChartShadowPlugin = {
    id: 'customShadow',
    beforeDraw: (chart: any) => {
        const ctx = chart.ctx
        ctx.save()

        const originalLineDraw = ctx.stroke
        if (!ctx._shadowApplied) {
            ctx.stroke = function () {
                ctx.save()
                ctx.shadowColor = '#1958FECC'
                ctx.shadowBlur = 4
                ctx.shadowOffsetX = 0
                ctx.shadowOffsetY = -2
                originalLineDraw.apply(this, arguments)
                ctx.restore()
            }

            ctx._shadowApplied = true
        }
    },

    afterDraw: (chart: any) => {
        if (chart.ctx._shadowApplied) {
            chart.ctx.restore()
            delete chart.ctx._shadowApplied
            delete chart.ctx.stroke
        }
    }
}

export const barChartShadowPlugin = {
    id: 'customShadowBar',
    beforeDraw: (chart: any) => {
        const ctx = chart.ctx
        ctx.save()

        const originalLineDraw = ctx.fill
        if (!ctx._shadowApplied) {
            ctx.fill = function () {
                ctx.save()
                ctx.shadowColor = '#1958FECC'
                ctx.shadowBlur = 8
                ctx.shadowOffsetX = 0
                ctx.shadowOffsetY = 2
                originalLineDraw.apply(this, arguments)
                ctx.restore()
            }

            ctx._shadowApplied = true
        }
    },

    afterDraw: (chart: any) => {
        if (chart.ctx._shadowApplied) {
            chart.ctx.restore()
            delete chart.ctx._shadowApplied
            delete chart.ctx.stroke
        }
    }
}
