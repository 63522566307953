import { copyToClipboard } from 'utils/copyToClipboard'
import formattedNumber from 'utils/formatNumber'
import CopyIcon from 'assets/copy.png'
import styles from './styles.module.scss'

interface Props {
    result: {
        transactionPerSec: number
        totalTransactions: number
    }
    isTesting: boolean
}

const Result: React.FC<Props> = props => {
    const { result, isTesting } = props

    const isDisabled =
        result.transactionPerSec === 0 && result.totalTransactions === 0

    const handleCopyResult = () => {
        if (isDisabled) return
        copyToClipboard(
            `Transactions Per Second: ${result.transactionPerSec},\nTotal Transactions: ${result.totalTransactions}`
        )
    }

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Result</span>

            <div className={styles.block}>
                <span className={styles.description}>
                    Transactions Per Second
                </span>
                <span className={styles.amount}>
                    {isTesting
                        ? '----------'
                        : formattedNumber(result.transactionPerSec)}
                </span>
            </div>

            <div className={styles.block}>
                <span className={styles.description}>Total Transactions</span>
                <span className={styles.amount}>
                    {isTesting
                        ? '----------'
                        : formattedNumber(result.totalTransactions)}
                </span>
            </div>

            <button
                className={`${styles.button} ${isDisabled ? styles.disabled : ''}`}
                onClick={handleCopyResult}
                style={{ visibility: `${isTesting ? 'hidden' : 'visible'}` }}
            >
                <img src={CopyIcon} alt='copy' />
                Copy
            </button>
        </div>
    )
}

export default Result
