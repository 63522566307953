import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Pie } from 'react-chartjs-2'
import styles from './styles.module.scss'

interface Props {
    percentTransactions: number[]
}

const Filledness: React.FC<Props> = props => {
    const { percentTransactions } = props

    const data = {
        labels: [
            'Node 1',
            'Node 2',
            'Node 3',
            'Node 4',
            'Node 5',
            'Node 6',
            'Node 7',
            'Node 8'
        ],
        datasets: [
            {
                label: 'Transactions',
                data: percentTransactions,
                borderColor: '#3360d11f',
                backgroundColor: (context: any) => {
                    if (!context.chart.chartArea) {
                        return
                    }
                    const {
                        ctx,
                        chartArea: { top, left, right, bottom }
                    } = context.chart
                    const centerX = (left + right) / 2
                    const centerY = (top + bottom) / 2
                    const radius = Math.min(right - left, bottom - top) / 2
                    const gradientBg = ctx.createRadialGradient(
                        centerX,
                        centerY,
                        0,
                        centerX,
                        centerY,
                        radius
                    )
                    gradientBg.addColorStop(0, '#9FBAFF')
                    gradientBg.addColorStop(1, '#477AFE')

                    return gradientBg
                }
            }
        ]
    }

    const options = {
        elements: {
            arc: {
                borderWidth: 0.5
            }
        },
        plugins: {
            datalabels: {
                color: '#8C93B2',
                anchor: 'end',
                align: 'end',
                formatter: function (value: any, context: any) {
                    return value
                        ? context.chart.data.labels[context.dataIndex]
                        : ''
                }
            }
        },
        layout: {
            padding: {
                left: 54,
                right: 54,
                top: 54,
                bottom: 54
            }
        }
    }
    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Node Filling</span>

            <div className={styles.pieWrapper}>
                <Pie
                    data={data}
                    options={options as any}
                    plugins={[ChartDataLabels as any]}
                />
            </div>
        </div>
    )
}

export default Filledness
