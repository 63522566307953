import formattedNumber from 'utils/formatNumber'
import styles from './styles.module.scss'

interface Props {
    total: number
}

const Total: React.FC<Props> = props => {
    const { total } = props

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Total Transactions</span>

            <span className={styles.amount}>{formattedNumber(total)}</span>

            <span className={styles.description}>
                Transactions Number Indicator
            </span>
        </div>
    )
}

export default Total
