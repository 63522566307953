import { Bar } from 'react-chartjs-2'
import styles from './styles.module.scss'

interface Props {
    finishedTransactions: number[]
}

const NodeChainResult: React.FC<Props> = props => {
    const { finishedTransactions } = props

    const data = {
        labels: [
            'Node 1',
            'Node 2',
            'Node 3',
            'Node 4',
            'Node 5',
            'Node 6',
            'Node 7',
            'Node 8'
        ],
        datasets: [
            {
                label: 'Transactions',
                data: finishedTransactions,
                borderRadius: 6,
                minBarLength: 4,
                backgroundColor: (context: any) => {
                    if (!context.chart.chartArea) {
                        return
                    }
                    const {
                        ctx,
                        data,
                        chartArea: { left, right }
                    } = context.chart
                    const gradientBg = ctx.createLinearGradient(
                        left,
                        0,
                        right,
                        0
                    )
                    gradientBg.addColorStop(0, '#D7E1FC')
                    gradientBg.addColorStop(0.6, '#1958FE')
                    return gradientBg
                }
            }
        ]
    }

    const options = {
        indexAxis: 'y' as 'y',
        barPercentage: 0.7,
        elements: {
            bar: {}
        },
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                ticks: {
                    color: '#8C93B2'
                }
            },
            x: {
                grid: {
                    display: false
                },
                border: {
                    display: false
                },
                ticks: {
                    display: false
                }
            }
        }
    }

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>
                Final result of forming all circuits on each node
            </span>

            <div className={styles.barWrapper}>
                <Bar data={data} options={options} />
            </div>
        </div>
    )
}

export default NodeChainResult
