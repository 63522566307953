import Arrow from '../../../assets/needle.svg'

interface Props {
    transactions: number
}

const ProgressBar: React.FC<Props> = props => {
    const { transactions } = props

    const maxValue = 10_000_000 * 2

    const strokeWidth = 4
    const radius = 50
    const circumference = 2 * Math.PI * radius

    const transactionsProgress =
        circumference - (transactions / maxValue) * circumference

    return (
        <>
            <svg width='280' height='140' viewBox='0 0 120 60'>
                <defs>
                    <linearGradient
                        id='progressGradient'
                        gradientTransform='rotate(-10)'
                    >
                        <stop offset='40%' stopColor='#6991f7' />
                        <stop offset='100%' stopColor='#d1deff' />
                    </linearGradient>

                    <clipPath id='clip'>
                        <rect x='0' y='0' width='120' height='60' />
                    </clipPath>
                    <filter
                        id='shadow'
                        x='-20%'
                        y='-20%'
                        width='140%'
                        height='140%'
                    >
                        <feGaussianBlur stdDeviation='1.6' />
                        <feOffset dx='0' dy='0' result='offsetblur' />
                        <feFlood
                            floodColor='#1958FE'
                            floodOpacity='1'
                            result='color'
                        />
                        <feComposite in2='offsetblur' operator='in' />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in='SourceGraphic' />
                        </feMerge>
                    </filter>
                </defs>
                <g clipPath='url(#clip)'>
                    <circle
                        cx='60'
                        cy='60'
                        r={radius}
                        stroke='#2D3341'
                        strokeWidth={strokeWidth}
                        fill='transparent'
                    />
                    <circle
                        cx='60'
                        cy='60'
                        r={radius}
                        stroke='url(#progressGradient)'
                        strokeWidth={strokeWidth}
                        fill='transparent'
                        strokeDasharray={circumference}
                        strokeDashoffset={transactionsProgress}
                        transform={'rotate(-180 60 60)'}
                        strokeLinecap='round'
                        style={{ transition: 'stroke-dashoffset 0.2s linear' }}
                        className={'circle2'}
                        filter='url(#shadow)'
                    />
                </g>
            </svg>
            <img
                src={Arrow}
                alt='arrow'
                style={{
                    transform: `rotate(${(transactions / maxValue) * 360 - 89}deg)`,
                    transition: 'transform 0.2s linear',
                    transformOrigin: 'bottom center',
                    position: 'absolute',
                    bottom: '185px',
                    height: '100px',
                    width: '9px'
                }}
            />
        </>
    )
}

export default ProgressBar
